import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, getIn } from 'formik';
import { FormikNumberFormat } from '../../components';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { createPaymentLinkRequest } from "../../requests/projectPayments";
import useCountryFactory from "../../hooks/useCountryFactory";
import { Copy } from "react-feather";

const PaymentLinkForm = ({ projectId, errors, touched }) => {
  const [link, setLink] = useState(null);
  const countryFactory = useCountryFactory();
  const dispatch = useDispatch();
  
  const copyLink = async () => {
    await navigator.clipboard.writeText(link);
    dispatch(sendAlert({kind: 'success', message: 'Url copiada al portapapeles'}));
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const handleSubmit = (values) => {
    createPaymentLinkRequest(projectId, {
      dispatch,
      params: { amount: Object.keys(values).length > 0 ? values.amount.replaceAll(".","") : null },
      successCallback: (response) => {
        setLink(response.data.link_payment);
      },
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
    <Formik initialValues={{}} onSubmit={(values) => {handleSubmit(values)}}>
      {() => (
        <Form>
          <Row>
            <Col md={6}>
              <Field name="amount">
                {({ field }) => (
                  <FormikNumberFormat
                    {...field}
                    leftAddon={countryFactory.getSymbol()}
                    label="Monto a cobrar"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={3}>
              <Button type="submit" variant="success" className='mt-4 ml-5' block>
                Generar Link
              </Button>
            </Col>
          </Row>
        </Form>
    )}
    </Formik>
    {link &&
      <Row className="d-flex justify-content-center my-3">
        <div onClick={copyLink}>
          <strong className="mr-2" style={{ cursor: 'pointer' }}>{link}</strong>
          <Copy size={15} role="button"/>
        </div>
      </Row>
    }
    </>
  );
};

export default PaymentLinkForm;
