/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useCallback } from 'react';
import { Image, Button, Row, Col } from 'react-bootstrap';
import { CheckCircle, FileText, Trash, Image as FeatherImage } from 'react-feather';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import heic2any from 'heic2any';

import defaultImg from '../../../assets/images/default.png';
import LoadSpiner from '../Spinner';
import getImage from '../../../utils/getImage';

const DropzoneComponent = props => {
  const {
    error,
    fileAccept,
    maxSize,
    minSize,
    multiple,
    name,
    onlyPersistedFiles,
    persistedFiles,
    touched,
    disabled: propDisabled,
    files: propFiles,
    maxFiles: propMaxFiles,
    imageRequired
  } = props;

  const [files, setFiles] = useState(propFiles);
  const [isDisabled, setIsDisabled] = useState(propDisabled || false);
  const [, setModalBody] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const maxFiles = multiple ? propMaxFiles : 1;

  useEffect(() => setIsDisabled(propDisabled), [propDisabled]);

  const removeFile = useCallback(
    fileId => {
      const { onDelete } = props;
      const newFiles = [];
      let fileToRemove;

      files.forEach(file => {
        if (file.code !== fileId) {
          newFiles.push(file);
        } else {
          fileToRemove = file;
        }
      });
      setFiles(newFiles);
      onDelete(fileToRemove);
      if (newFiles.length < maxFiles) {
        setIsDisabled(false);
      }
    },
    [files, maxFiles, props]
  );

  const removePersistedFile = useCallback(
    fileId => {
      const { onDeletePersistedFiles } = props;
      const newFiles = [];
      let fileToRemove;

      persistedFiles.forEach(file => {
        if (file.id !== fileId) {
          newFiles.push(file);
        } else {
          fileToRemove = file;
        }
      });

      onDeletePersistedFiles(fileToRemove);
      if (newFiles.length < maxFiles) {
        setIsDisabled(false);
      }
    },
    [persistedFiles, maxFiles, props]
  );

  // If further optimization is needed on the future the prop onlyPersistedFiles should be added
  // on addFiles to prevent addin documents on files array [prop files array]
  const addFiles = async newFiles => {
    const { duplicateFile, batchNumber, onDrop, onDropUploaded } = props;
    let currentFiles = files;

    const handleReaderLoadEnd = (reader, code, resolve) => {
      const newFile = currentFiles.map(f => {
        if (f.code === code) return { ...f, result: reader.result };
        return f;
      });
      currentFiles = newFile;
      setFiles(newFile);
      resolve();
    };

    for (let file of newFiles) {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const blob = await heic2any({
          blob: file,
          toType: 'image/jpeg'
        });
        let fileName = file.name.replace('.heic', '.jpeg');
        file = new File([blob], fileName, { type: 'image/jpeg' });
      }
      if (maxFiles !== undefined && files.length + 1 >= maxFiles) {
        setIsDisabled(true);
        if (files.length >= maxFiles) continue;
      }
      if (!duplicateFile) {
        const duplicate = files.filter(f => f.name === file.name && f.size === file.size && f.type === file.type);
        if (duplicate.length) continue;
      }

      const reader = new FileReader();
      const code = Math.floor(Math.random() * 10000000000).toString(16);
      currentFiles = [
        ...currentFiles,
        {
          code,
          name: file.name,
          result: defaultImg,
          size: file.size,
          type: file.type,
          file,
          loaded: false,
          uploaded: false
        }
      ];
      setFiles(currentFiles);

      await new Promise(resolve => {
        reader.onloadend = () => handleReaderLoadEnd(reader, code, resolve);
        reader.readAsDataURL(file);
      });
    }

    const { id: recordId } = props;
    const addedFiles = [];
    let newTotalFiles = [];

    currentFiles.forEach(f => {
      if (!f.loaded) addedFiles.push(f);
      newTotalFiles.push({ ...f, loaded: true });
    });
    currentFiles = newTotalFiles;
    setFiles(newTotalFiles);

    const batchN = batchNumber || addedFiles.length;
    const rangeBatchNumber = Math.ceil(addedFiles.length / batchN);
    let batchFiles;

    for (let index = 0; index < rangeBatchNumber; index += 1) {
      batchFiles = addedFiles.slice(index * batchN, (index + 1) * batchN);
      const filesToFetch = batchFiles.map(bf => ({
        document_key: bf.code,
        document: bf.file
      }));
      const response = await onDrop(recordId, filesToFetch);

      if (response && response.status === 201) {
        const result = await response.data;
        newTotalFiles = currentFiles.map(ntf => {
          const fileUploaded = result.filter(r => r.document_key === ntf.code)[0];
          return fileUploaded ? { ...ntf, uploaded: true, id: fileUploaded.id } : ntf;
        });
      }
      onDropUploaded(newTotalFiles);
      setFiles(newTotalFiles);
    }
  };

  const classContainer = ({ isDragActive, isDragReject, isFileTooLarge, isDisabledProp }) => {
    const {
      classes: { container, reject, active, disabled }
    } = props;
    let className = container;
    if (isDragReject || isFileTooLarge) {
      className += ` ${reject}`;
      return className;
    }
    if (isDragActive) className += ` ${active}`;
    if (isDisabledProp) className += ` ${disabled}`;
    return className;
  };

  const preview = useCallback(
    allFiles =>
      allFiles.map(file => (
        <Col
          md={4}
          sm={4}
          xs={6}
          key={file.code}
          className="mb-1"
          style={{ fontSize: '12px', fontWeight: 400, color: '#202020' }}
        >
          <div className="box-img">
            <div className="content">
              {file.type.includes('image') ? (
                <Image src={file.result} className="image-fit" style={{ maxWidth: '100%' }} rounded />
              ) : (
                <FileText className="icon-file-text" strokeWidth="1.5" />
              )}
              <div className="hover-dropzone">
                {file.uploaded ? (
                  <CheckCircle className="big-svg text-success mb-2" strokeWidth="1.5" />
                ) : (
                  <LoadSpiner />
                )}
                {file.uploaded && (
                  <Button variant="danger" className="image-delete" size="sm" onClick={() => removeFile(file.code)}>
                    <Trash className="small-svg" strokeWidth="1.5" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>
      )),
    [removeFile]
  );

  const previewPersistedFiles = useCallback(
    allFiles =>
      allFiles.map(file => (
        <Col md={3} key={file.id}>
          <div className="dropzone-box">
            <div className="box-img">
              <div className="content">
                {file.type.includes('image') ? (
                  <Image src={getImage(file.key, 400, 400, 'inside')} className="image-fit" width="100px" />
                ) : (
                  <FileText className="icon-file-text" strokeWidth="1.5" />
                )}
                {!isDisabled && (
                  <div className="hover-dropzone">
                    <Button
                      variant="danger"
                      className="image-delete"
                      size="sm"
                      onClick={() => removePersistedFile(file.id)}
                    >
                      <Trash className="small-svg" strokeWidth="1.5" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <p>{file.name}</p>
          </div>
        </Col>
      )),
    [removePersistedFile, isDisabled]
  );

  const previewModalShow = useCallback(
    modalFiles => {
      setModalShow(true);
      setModalBody(
        <Row>
          {!onlyPersistedFiles && preview(modalFiles)}
          {previewPersistedFiles(persistedFiles)}
        </Row>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [persistedFiles, preview, previewPersistedFiles]
  );

  useEffect(() => {
    if (modalShow) previewModalShow(files);
  }, [files, modalShow, previewModalShow]);

  return (
    <Dropzone
      onDrop={newFiles => addFiles(newFiles)}
      disabled={isDisabled}
      multiple={multiple}
      maxSize={maxSize}
      minSize={minSize}
      accept={fileAccept}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
        const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0]?.size > maxSize;

        return (
          <section className="dropzone-section">
            <Row className="dropzone-order">
              <Col
                md={12}
                {...getRootProps()}
                className={`d-flex dropzone-images-btn ${classContainer({
                  isDragActive,
                  isDragReject,
                  isFileTooLarge,
                  isDisabledProp: isDisabled
                })}`}
              >
                <input {...getInputProps()} name={name} accept="image/*,text/plain" />
                <p className="fix-d">
                  <span style={{ color: '#00517B', cursor: 'pointer' }} className="ml-2 ont-weight-bold">
                    <FeatherImage className="icon-file-text" strokeWidth="1.5" size={16} />
                    <span className="ml-1">Subir fotos</span>
                  </span>
                  <span>{imageRequired ? <abbr className="text-danger">*</abbr> : ''}</span>
                </p>
              </Col>
              <Col md={12} className="text-center dropzone-images-error-message mb-2">
                {error && touched && <small className="text-danger is-invalid">{error}</small>}
              </Col>

              <Col md={12} className="full-row dropzone-images-text">
                <Row>
                  {preview(files)}
                  {previewPersistedFiles(persistedFiles)}
                </Row>
              </Col>
            </Row>
          </section>
        );
      }}
    </Dropzone>
  );
};

DropzoneComponent.propTypes = {
  texts: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  persistedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number,
  onDrop: PropTypes.func,
  onDropUploaded: PropTypes.func,
  onDelete: PropTypes.func,
  onDeletePersistedFiles: PropTypes.func,
  batchNumber: PropTypes.number,
  duplicateFile: PropTypes.bool,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  fileAccept: PropTypes.string,
  modalPreview: PropTypes.bool
};

DropzoneComponent.defaultProps = {
  texts: {
    textDefault: (
      <p className="fix-d">
        <FeatherImage className="icon-file-text" strokeWidth="1.5" />
        <span>Subir fotos</span>
      </p>
    ),
    reject: 'Archivo no valido para subir.',
    active: 'Suelta los archivos aquí para subir...',
    disabled: 'Alcanzaste la cantidad máxima de archivos.',
    tooLarge: 'El archivo excede el peso máximo permitido.'
  },
  classes: {
    container: 'dropzone-container',
    reject: 'dropzone-reject',
    active: 'dropzone-active',
    disabled: 'dropzone-disabled'
  },
  files: [],
  persistedFiles: [],
  id: Date.now(),
  onDrop: (id, files) => console.log(id, files),
  onDropUploaded: files => console.log(files),
  onDelete: file => console.log(file),
  onDeletePersistedFiles: file => console.log(file),
  batchNumber: undefined,
  duplicateFile: true,
  maxFiles: undefined,
  disabled: false,
  multiple: true,
  maxSize: undefined,
  minSize: undefined,
  fileAccept: '',
  modalPreview: false
};

export default DropzoneComponent;
