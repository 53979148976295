import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Field, getIn, useFormikContext} from 'formik';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import camelCaseRecursive from "camelcase-keys-recursive";
import { validateToken } from '../../../actions/auth';
import { sendAlert } from '../../../actions/utils';
import { FormikSelect, InputRemoteSelect } from '../../../components';
import { debounceIndexProductCategoriesRequest } from '../../../requests/productCategories';
import {debounceIndexProjectsRequest, indexProjectsRequest} from '../../../requests/projects';
import { pendingDetailsPurchaseManagementsRequest } from '../../../requests/purchaseManagements';
import { camelCaseEmptyStringRecursive, copyObject, textCapitalize, textLowerCase } from '../../../services/utils';
import { otStateTypes } from '../../Project/Form/FormOptions';
import AddPurchaseManagementDetail from './AddPurchaseManagementDetail';

const newPurchaseManagementDetail = {
  categoryId: '',
  categoryName: '',
  parsedUnitPrice: '',
  product: {},
  productCategoryId: '',
  productCategoryName: '',
  productId: '',
  productNameCustom: '',
  projectDetail: {},
  projectDetailId: '',
  quantity: '',
  unitPrice: ''
};

const LoadItemsForm = ({ purchaseManagementDetailsAttributes: pmdAttributes, errors, touched }) => {
  const { setFieldValue, values } = useFormikContext();
  const [loadMsg, setLoadMsg] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [otState, setOtState] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const { user: currentUser } = useSelector(state => state.auth);
  const { id, currentBranchOffice } = currentUser;

  const userData = () => {
    if (id === 0) dispatch(validateToken());
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchProductCategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        filter_parents: true,
        for_selector: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(response.data.data),
      failureCallback: handleFailureRequest
    });
  };

  const handleCleanFilters = () => {
    setOtState('');
    setProductCategory('');
    setSelectedProject('');
  };

  const loadingMessage = (text, success) => {
    return success ? `${textCapitalize(text)} cargados con éxito` : `No hay ${textLowerCase(text)} por cargar`;
  };

  const copyDetails = (details, from) => {
    const copiedDetails = details.map(detail => {
      const ids = ['categoryId', 'productCategoryId', 'productId', 'projectDetailId'];
      const models = ['product', 'vehicle', 'projectDetail'];
      const names = ['categoryName', 'productCategoryName', 'productName', 'productNameCustom'];
      const quantities = ['quantity', 'totalPrice', 'unitPrice', 'freeQuotedCostWithTax'];
      const allKeys = [ids, models, names, quantities].flat();

      // copy object-specific keys
      const objectCreated = copyObject(detail, allKeys);
      if (from === 'project') {
        objectCreated.projectDetail = detail;
        objectCreated.projectDetailId = detail.id;
        objectCreated.totalPrice = detail.totalCost;
        objectCreated.unitPrice = detail.product?.costQuotation || 0;
      } else objectCreated.parentId = detail.id;

      return objectCreated;
    });

    return copiedDetails.filter(detail => {
      const findMatch = pmdAttributes.filter(pmd => pmd.projectDetailId === detail.projectDetailId);
      return findMatch.length === 0;
    });
  };

  const loadingItems = projects => {
    const projectDetails = [];
    projects.map(project => {
      return project.projectWorksAttributes.map(pw => {
        let allDetails = pw.projectDetailsAttributes.filter(pd => {
          return pd.hasOnePurchase === false && pd.requestPurchase === true && pd.purchaseMade === false;
        });
        if (Object.keys(productCategory).length > 0) {
          allDetails = allDetails.filter(pd => pd.categoryId === productCategory.value);
        }
        projectDetails.push(allDetails);
        return allDetails;
      });
    });

    return projectDetails.flat();
  };

  const preloaded = (preloadedDetails, from) => {
    let details = preloadedDetails;
    if (from === 'project') details = loadingItems(preloadedDetails);
    const copiedDetails = copyDetails(details, from);

    const messageTitle = from === 'project' ? 'ítems' : 'pendientes';
    const message = loadingMessage(messageTitle, copiedDetails.length > 0);
    setLoadMsg(message);

    return copiedDetails;
  };

  const addPurchaseManagementDetail = ({ preloadedDetails = null, from }) => {
    let allPurchases = [...pmdAttributes, newPurchaseManagementDetail];
    if (preloadedDetails !== null) allPurchases = [...pmdAttributes, preloaded(preloadedDetails, from)].flat();
    else setLoadMsg('');
    setFieldValue('purchaseManagement[purchaseManagementDetailsAttributes]', allPurchases);
    handleCleanFilters();
  };

  const handleSuccessProjectsRequest = response => {
    const { data: projects_data } = camelCaseEmptyStringRecursive(response.data);
    addPurchaseManagementDetail({ preloadedDetails: projects_data, from: 'project' });
    setOnRequest(false);
  };

  const fetchProjects = () => {
    if (!(selectedProject?.code)) {
      if (Object.keys(otState).length === 0) return;
    }
    setOnRequest(true);
    const sendParams = {
      branch_office_id: currentBranchOffice.value,
      purchase_made: false,
      state_id: selectedProject?.code ? '' : otState.value,
      with_associations: true,
      filter_code: selectedProject?.code || ''
    };
    if (Object.keys(productCategory).length > 0) sendParams.category_id = productCategory.value;

    indexProjectsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessProjectsRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessPendingDetailsRequest = response => {
    const { data: pendingDetails } = camelCaseEmptyStringRecursive(response);
    addPurchaseManagementDetail({ preloadedDetails: pendingDetails });
  };

  const fetchPendingDetails = () => {
    setOnRequest(true);
    pendingDetailsPurchaseManagementsRequest({
      dispatch,
      successCallback: handleSuccessPendingDetailsRequest,
      callback: () => setOnRequest(false)
    });
  };

  const fetchOTs = (inputValue, callback) => {
    debounceIndexProjectsRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'created_at',
        sort_direction: 'desc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchInitialProjects = useCallback(
    params => {
      indexProjectsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          for_selector: true,
          sort_column: 'created_at',
          sort_direction: 'desc',
          display_length: 100
        },
        successCallback: response => {
          setProjects(camelCaseRecursive(response.data.data));
          setSelectedProject('');
        }
      });
    },
    [stableDispatch]
  );

  useEffect(userData, []);

  useEffect(fetchInitialProjects, [fetchInitialProjects]);
  // useEffect(fetchOTs, [projectId]);

  return (
    <Row className="work-box">
      <Col md={12}>
        <Row className="px-1 py-4 mt-3">
          <Col md={2}>
            <Field>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Estado OT"
                  placeholder="Seleccionar Estado"
                  value={otState}
                  onChange={data => setOtState(camelCaseEmptyStringRecursive(data))}
                  options={otStateTypes}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field>
              {({ field }) => (
                <InputRemoteSelect
                  {...field}
                  label="Categoría"
                  placeholder="Seleccionar Categoría"
                  value={productCategory}
                  onChange={data => setProductCategory(camelCaseEmptyStringRecursive(data))}
                  request={fetchProductCategories}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field>
              {({ field }) => (
                <InputRemoteSelect
                  {...field}
                  isClearable
                  label="OT Referencia"
                  placeholder="Seleccionar Orden de Trabajo"
                  defaultOptions={projects}
                  value={selectedProject}
                  onChange={data => {
                    setSelectedProject(data);
                    setFieldValue(field.name, data ? data.value : '');
                  }}
                  request={fetchOTs}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2} className="d-flex align-items-center mt-2 px-4">
            <Button block variant="success" onClick={fetchProjects} disabled={onRequest}>
              Cargar ítems
            </Button>
          </Col>
          <Col md={2} className="d-flex align-items-center mt-2 px-4">
            <Button block variant="warning" onClick={fetchPendingDetails} disabled={onRequest}>
              Cargar pendientes
            </Button>
          </Col>
          <Col md={2} className="d-flex align-items-center mt-2 pl-0">
            {onRequest ? <Spinner animation="border" variant="primary" size="sm" /> : <p className="mb-0">{loadMsg}</p>}
          </Col>
        </Row>
      </Col>

      {pmdAttributes && pmdAttributes.length > 0 && (
        <Col md={12}>
          <Row className="work-details-container px-1 mb-4">
            <Col md={12}>
              <hr className="mt-0 pb-3" />
              <AddPurchaseManagementDetail purchaseManagement={values.purchaseManagement} />
            </Col>
          </Row>
        </Col>
      )}

      <Col md={2} className="ml-1 mb-4">
        <Button block variant="secondary" onClick={addPurchaseManagementDetail}>
          Agregar nuevo ítem
        </Button>
      </Col>
    </Row>
  );
};

export default LoadItemsForm;
