import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PaymentLinkForm from './PaymentLinkForm';
import { SimpleCenteredModal } from '../../components';

const PaymentLinkButton = ({ variant = 'outline-primary', project }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const { id } = project;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleOpenModal = () => {
    setModalShow(true);
    setModalBody(
      <PaymentLinkForm
        projectId={id}
        handleClose={handleModalClose}
      />
    );
  };

  return (
    <>
      <Button variant={variant} className="w-100 d-block" onClick={handleOpenModal}>
        Generar Link de Pago
      </Button>
      {modalShow && (
        <SimpleCenteredModal
          title="Generar Link de Pago"
          body={modalBody}
          show={modalShow}
          onHide={handleModalClose}
          closeButton
        />
      )}
    </>
  );
};

export default PaymentLinkButton;
