import React, { useState } from 'react';
import { Col, Dropdown, Button, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import CollectionPaymentButton from '../../ProjectPayment/CollectionPaymentButton';
import PaymentLinkButton from '../../ProjectPayment/PaymentLinkButton';
import ProjectProgressEmailButton from './ProjectProgressEmailButton';
import ProjectDetailAdvancedConfiguration from '../../ProjectDetail/ProjectDetailAdvancedConfiguration';
import { SimpleCenteredModal } from '../../../components';
import ProjectQuoteTable from './ProjectQuoteTable';
import { getChecklistPdfRequest, getPdfRequest } from '../../../requests/projects';
import downloadFile from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';

const ProductShow = ({ project, publicVersion, setMoreData, preview, token, version }) => {
  const {
    id,
    parsedTotalCost: parsedProjectTotalCost,
    parsedTotalDiscount,
    parsedTotalPriceWithDiscount,
    parsedTotalMargin,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    totalCost,
    totalPrice,
    parsedMarginAmount,
    checklistsAttributes,
    otState,
    paymentServiceEnabled
  } = project;
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const isDiscounted = () => {
    if (parsedTotalDiscount) {
      const discount = parseInt(parsedTotalDiscount.substring(1), 10);
      if (discount) return true;
    }
    return false;
  };

  const downloadPdf = pdfType => {
    getPdfRequest({
      id,
      type: pdfType,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response),
      failureCallback: error => dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }))
    });
  };

  const downloadChecklistPdf = type => {
    getChecklistPdfRequest({
      id,
      type,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response)
    });
  };

  const handleAdvancedConfiguration = (vProjectDetail, inputName, productId) => {
    setModalShow(true);
    setModalBody(
      <Formik initialValues={{ project }} onSubmit={() => null}>
        <ProjectDetailAdvancedConfiguration
          inputName={inputName}
          vProjectDetail={vProjectDetail}
          onHide={() => setModalShow(false)}
          restoreOriginalValues={() => setModalShow(false)}
          originalValues={vProjectDetail}
          setOriginalValues={() => setMoreData(m => !m)}
          handleEditConfirmation={() => history.push(`/products/${productId}/edit`)}
        />
      </Formik>
    );
  };

  return (
    <Row>
      <Col md={paymentServiceEnabled ? 4 : 6} className="mb-3">
        <strong>Ítems</strong>
      </Col>
      <Col md={2} className="mb-3">
        {!publicVersion && <ProjectProgressEmailButton variant="warning" project={project} />}
      </Col>
      {paymentServiceEnabled &&
      <Col md={2} className="mb-3">
        {!publicVersion && <PaymentLinkButton project={project} />}
      </Col>
      }
      <Col md={2} className="mb-3">
        {!publicVersion && <CollectionPaymentButton project={project} />}
      </Col>
      <Col md={2} className="mb-3">
        <Dropdown>
          <Dropdown.Toggle as={Button} id="dropdown-basic-button" className="btn-block">
            Descargar PDFs
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {checklistsAttributes?.map((checklistType, index) => (
              <Dropdown.Item key={index} onClick={() => downloadChecklistPdf(checklistType.checklistType)}>
                PDF {checklistType.name}
              </Dropdown.Item>
            ))}
            <Dropdown.Item onClick={() => downloadPdf('quote')}>PDF Presupuesto</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('onepager')}>PDF resumen/caja</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('quality_control')}>PDF de Calidad</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('work_checklist')}>PDF Checklist de trabajos</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      {(!publicVersion || otState !== 'in_process' || preview) &&   (
        <Col md={12}>
          <ProjectQuoteTable
            project={project}
            publicVersion={publicVersion}
            handleAdvancedConfiguration={handleAdvancedConfiguration}
            setMoreData={setMoreData}
            token={token}
            version={version}
          />
        </Col>
      )}
      {!publicVersion && (
        <Col md={{ span: 6, offset: 6 }}>
          <Table responsive borderless hover>
            <thead className="border-top-borderless-table">
              <tr className="header-border-bottom">
                <th>Resumen Financiero de Proyecto</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr className="font-weight-bold">
                <td>Costo Total Neto Proyecto</td>
                <td>{parsedProjectTotalCost}</td>
              </tr>
              <tr className="font-weight-bold">
                <td>Valor Total Neto Proyecto</td>
                <td>{parsedTotalPrice}</td>
              </tr>
              {isDiscounted() && (
                <>
                  <tr className="font-weight-bold">
                    <td>Descuento</td>
                    <td className="text-secondary">- {parsedTotalDiscount}</td>
                  </tr>
                  <tr className="font-weight-bold">
                    <td>Valor Total Neto Real</td>
                    <td>{parsedTotalPriceWithDiscount}</td>
                  </tr>
                </>
              )}
              <tr className="font-weight-bold">
                <td>Valor Total Proyecto (inc. IVA)</td>
                <td>
                  {parsedTotalPriceTax}{' '}
                  <span className="font-weight-normal font-italic">{`(IVA: ${parsedTotalTax})`}</span>
                </td>
              </tr>
              <tr className="font-weight-bold">
                <td>Margen Proyecto</td>
                <td className={totalPrice - totalCost > 0 ? 'text-success' : 'text-danger'}>
                  {parsedProjectTotalCost ? `${parsedTotalMargin} (${parsedMarginAmount})` : 'No aplica'}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      )}
      <SimpleCenteredModal size="md" title="Configuración Avanzada" body={modalBody} show={modalShow} />
    </Row>
  );
};

export default ProductShow;
