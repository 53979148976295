/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import memoize from 'memoize-one';
import { AlertTriangle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { BasicActionBtns } from '../../components';

const columns = memoize((clickHandler, company) => {
  const {
    user: { roleIds = [] }
  } = useSelector(state => state.auth);

  const isAdminUser = roleIds.includes(1);

  const showAlert = item => {
    const { current_stock, minimum_stock } = item;
    if (current_stock <= minimum_stock) {
      return (
        <>
          <span className="w-50 text-center">{current_stock}</span>
          <AlertTriangle data-tip data-for="stock-alert" size={10} color="red" className="w-25" />
          <ReactTooltip id="stock-alert" place="right">
            Bajo Stock Crítico
          </ReactTooltip>
        </>
      );
    }
    return <span className="w-50 text-center">{current_stock}</span>;
  };

  const array = [
    {
      name: 'NOMBRE',
      selector: 'product_name',
      sortable: true,
      cell: item => <Link to={`/products/${item.product_id}`}>{item.product_name}</Link>
    },
    {
      name: 'SKU',
      grow: '1',
      selector: 'product_sku',
      cell: item => item.product?.sku,
      sortable: true
    },
    {
      name: 'CATEGORÍA',
      grow: '1',
      selector: 'category_name',
      sortable: true
    },
    {
      name: 'SUBCATEGORÍA',
      grow: '1',
      selector: 'product_category_name',
      sortable: true
    }
  ];
  if (company) {
    array.push({
      name: 'SUCURSAL',
      selector: 'branch_office_name',
      cell: item => item.branch_office?.label,
      sortable: true
    });
  }
  return [
    ...array,
    {
      name: 'PRECIO COSTO',
      selector: 'product_cost',
      cell: item => item.product?.parsed_cost,
      sortable: true
    },
    {
      name: 'PRECIO VENTA',
      selector: 'product_sale_price',
      cell: item => item.product?.parsed_sale_price,
      sortable: true
    },
    {
      name: 'STOCK POTENCIAL',
      selector: 'potential_stock',
      sortable: true
    },
    {
      name: 'STOCK CRÍTICO',
      selector: 'minimum_stock',
      sortable: true
    },
    {
      name: 'STOCK ACTUAL',
      selector: 'current_stock',
      cell: item => showAlert(item),
      sortable: true
    },
    {
      name: 'UBICACIÓN',
      selector: 'location'
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={clickHandler}
          withDestroy={company && isAdminUser}
          withShow={false}
          editClick
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '123px'
    }
  ];
});

export default columns;
