import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import CollectionPaymentEmailForm from './CollectionPaymentEmailForm';
import { SimpleCenteredModal } from '../../components';
import { sendEmailRequest } from '../../requests/projects';
import { showCompanyRequest } from '../../requests/companies';
import { createPaymentLinkRequest } from "../../requests/projectPayments";
import { sendAlert } from '../../actions/utils';

const CollectionPaymentButton = ({ variant = 'info', project }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [collectionEmailBody, setCollectionEmailBody] = useState('');
  const [collectionEmailSecondaryBody, setCollectionEmailSecondaryBody] = useState('');
  const dispatch = useDispatch();
  const { id } = project;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleSuccessSendCollectionEmail = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Mail de cobranza ha sido enviado exitosamente' }));
    handleModalClose();
  };

  const handleSendCollectionEmail = params => {
    sendEmailRequest({
      id,
      type: 'collection',
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessSendCollectionEmail,
      failureCallback: handleFailureRequest
    });
  };

  const handleOpenModal = () => {
    setModalShow(true);
    if (project.paymentServiceEnabled) {
      createPaymentLinkRequest(id, {
        dispatch,
        successCallback: (response) => {
          setModalBody(
            <CollectionPaymentEmailForm
              project={project}
              collectionEmailTitle={`Recuerdo de cobro de su vehículo con patente/código ${project?.vehiclePlate}`}
              collectionEmailBody={collectionEmailBody}
              collectionEmailSecondaryBody={collectionEmailSecondaryBody}
              projectId={id}
              paymentLink={response.data.link_payment}
              formRequest={handleSendCollectionEmail}
              handleClose={handleModalClose}
            />
          );
        },
        failureCallback: () => {
          setModalBody(
            <CollectionPaymentEmailForm
              project={project}
              collectionEmailTitle={`Recuerdo de cobro de su vehículo con patente/código ${project?.vehiclePlate}`}
              collectionEmailBody={collectionEmailBody}
              collectionEmailSecondaryBody={collectionEmailSecondaryBody}
              projectId={id}
              formRequest={handleSendCollectionEmail}
              handleClose={handleModalClose}
            />
          );
        }
      });
    } else {    
      setModalBody(
        <CollectionPaymentEmailForm
          project={project}
          collectionEmailTitle={`Recuerdo de cobro de su vehículo con patente/código ${project?.vehiclePlate}`}
          collectionEmailBody={collectionEmailBody}
          collectionEmailSecondaryBody={collectionEmailSecondaryBody}
          projectId={id}
          formRequest={handleSendCollectionEmail}
          handleClose={handleModalClose}
        />
      );
    }
  };

  const handleShowCompanySuccess = response => {
    const { collectionEmailBodyText, collectionEmailSecondaryBodyText } = camelCaseRecursive(response.data);
    setCollectionEmailBody(collectionEmailBodyText);
    setCollectionEmailSecondaryBody(collectionEmailSecondaryBodyText);
  };

  const handleGetCompanyData = () => {
    showCompanyRequest({
      dispatch,
      successCallback: handleShowCompanySuccess,
      failureCallback: handleFailureRequest
    });
  };

  useState(handleGetCompanyData, []);

  return (
    <>
      <Button variant={variant} className="w-100 d-block" onClick={handleOpenModal}>
        Enviar Email Cobranza
      </Button>
      {modalShow && (
        <SimpleCenteredModal
          title="Enviar email cobranza"
          body={modalBody}
          show={modalShow}
          onHide={handleModalClose}
          closeButton
        />
      )}
    </>
  );
};

export default CollectionPaymentButton;
