import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import '../../services/yupCustomMethods';
import { FormikInput, BrandModelSelect } from '../../components';
import WorkDetailAdd from './WorkDetailAdd';

const WorkForm = ({
  action,
  errors,
  from,
  handleModalClose,
  onHide,
  setFieldValue,
  submitVariant,
  touched,
  values,
  work
}) => {
  const {
    user: { companyAttributes }
  } = useSelector(state => state.auth);
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const { workDetailsAttributes } = values.work;
  const [net, setNet] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);

  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(workDetailsAttributes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue('work[workDetailsAttributes]', items);
  };

  useEffect(() => {
    const sumSubtotal = workDetailsAttributes.reduce((acc, { price, _destroy }) => {
      if (_destroy) {
        return acc;
      }
      return acc + price;
    }, 0);
    const taxRatePercentage = parseFloat(companyAttributes?.taxRate || 0) / 100;
    const newIva = parseFloat(sumSubtotal * taxRatePercentage);
    const newTotal = sumSubtotal + newIva;
    setNet(sumSubtotal);
    setIva(newIva);
    setTotal(newTotal);
  }, [workDetailsAttributes]);

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Field name="work[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {from !== 'ProjectWork' && (
          <Col md={8}>
            <Row>
              <BrandModelSelect
                modelKey="work"
                currentModel={work}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            </Row>
          </Col>
        )}
      </Row>
      <Row className="mb-2">
        <Col md={3} className="font-weight-bold">DETALLES DEL TRABAJO</Col>
        <Col md={{ span: 2, offset: 3 }} className="font-weight-bold">
          NETO: ${net.toLocaleString('es-CL')}
        </Col>
        <Col md={2} className="font-weight-bold">
          IVA: ${iva.toLocaleString('es-CL')}
        </Col>
        <Col md={2} className="font-weight-bold">
          TOTAL: ${total.toLocaleString('es-CL')}
        </Col>
      </Row>
      <Row>
        <Col>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="projectWorks">
              {provided => (
                <div {...provided?.droppableProps} ref={provided?.innerRef}>
                  <WorkDetailAdd
                    setFieldValue={setFieldValue} touched={touched} errors={errors} values={values}
                  />
                  {provided?.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { work } = props;
  return { work };
};

const validationSchema = Yup.object().shape({
  work: Yup.object().shape({
    manHour: Yup.number().nullable(),
    name: Yup.string().required('Debes ingresar un nombre'),
    vehicleBrandId: Yup.string().nullable(),
    vehicleModelId: Yup.string().nullable(),
    workDetailsAttributes: Yup.array().of(
      Yup.object().shape({
        categoryId: Yup.number().required('Debes seleccionar una categoría'),
        copyPrice: Yup.boolean(),
        price: Yup.number().nullable(),
        productCategoryId: Yup.number().nullable(),
        productId: Yup.number().nullable(),
        productNameCustom: Yup.string().nullable(),
        quantity: Yup.number().nullable(),
        unitPrice: Yup.number().nullable()
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(WorkForm);
